export default function (args) {
  return new Promise((resolve, reject) => {
    const config = {
      containerId: args.containerId,
      containerClass: args.containerClass,
      content: args.content,
      duration: args.duration || 3000,
      onHidden: args.onHidden || function () {},
      onShown: args.onShown || function () {}
    }

    const defaultContainerClass = 'v-notification-modal'
    var modalId = config.containerId || 'html-notification-model'
    var modalClass = config.containerClass
    var modalDom
    window.modalonHidden = config.onHidden || function () {}

    function controller() {
      appendModal()

      listenHideModal()

      listenShowModal()

      modalDom.hide = hideModal
      window.closeHNotificationModal = hideModal

      showModal()

      setTimeoutToClose()

      setTimeout(resolve, 1000)
    }

    function setTimeoutToClose() {
      setTimeout(() => {
        hideModal()
      }, config.duration)
    }

    function appendModal() {
      const containerClass = [
        defaultContainerClass,
        'modal',
        'fade',
        modalClass
      ]
        .filter((i) => i)
        .join(' ')

      var modalStr = [
        `<div id='${modalId}' class='${containerClass}'>`,
        `<div class='modal-dialog modal-sm'>`,
        "<div class='modal-content'>",

        // body
        "<div class='modal-body'>",
        config.content,
        '</div>',

        '</div>',
        '</div>',
        '</div>'
      ]
        .filter((elm) => elm)
        .join('')

      var parser = new DOMParser()
      var modalHtml = parser.parseFromString(modalStr, 'text/html')
      window.modalHtml = modalHtml
      modalDom = $(modalHtml).find(`.${defaultContainerClass}`)

      $('body').append(modalDom)
    }

    function listenHideModal() {
      modalDom.on('hidden.bs.modal', function (e) {
        config.onHidden()

        modalDom.remove()
      })
    }

    function listenShowModal() {
      modalDom.on('shown.bs.modal', function (e) {
        config.onShown(modalDom)
      })
    }

    function showModal() {
      setTimeout(function () {
        modalDom.modal({
          backdrop: false,
          keyboard: false
        })
      }, 0)
    }

    function hideModal() {
      modalDom.modal('hide')
    }

    controller()
  })
}
